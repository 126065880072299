<template>
    <div>
        <!-- Use content-class if required since content is moved to the beginning of the v-app component (unless the attach prop is provided) -->
        <v-snackbar
            max-width="1080px"
            width="fit-content"
            class="custom-snackbar"
            content-class=""
            :timeout="-1"
            :color="snackConfig.snackColor"
            :location="(location as any)"
            v-model="snackbar"
        >
            <slot name="content">
                <div
                    theme="dark"
                    @mouseover="hoverSnackbar = true"
                    @mouseleave="hoverSnackbar = false"
                    class="d-flex align-center justify-space-between pa-3"
                >
                    <v-icon v-if="!noIcon" color="white" size="20" class="ml-1 mr-4" :icon="snackConfig.snackIcon"></v-icon>

                    <div class="body-2">
                        <span class="font-weight-bold" v-if="snackConfig.snackTitle">{{ snackConfig.snackTitle }} - </span>
                        <span> {{ snackConfig.snackContent }} </span>
                    </div>

                    <v-btn
                        v-if="!noButton"
                        class="ml-4"
                        size="small"
                        icon="i-mdi:close-circle"
                        variant="text"
                        color="white"
                        @click="hideAndResetSnackBar()"
                    >
                    </v-btn>
                </div>
            </slot>

            <v-progress-linear
                class="progressBarTransition pa-0 ma-0"
                :color="progressBarColor"
                rounded
                :model-value="cubicBezierNumber"
                style="position: absolute; bottom: 0; top: auto"
            />
        </v-snackbar>
    </div>
</template>

<script setup lang="ts">
import { useTransition } from "@vueuse/core";

interface SnackBarConfigInterface {
    snackTimeout: number;
    snackColor: string;
    snackIcon?: string;
    snackTitle?: string;
    snackContent?: string;
}

const prop = defineProps({
    snackConfig: {
        type: Object as PropType<SnackBarConfigInterface>,
        required: true,
        default() {
            return {
                snackTimeout: 5000,
                snackColor: "primary",
                snackIcon: "i-mdi:message-text",
                snackTitle: "",
                snackContent: "Something Happened.",
            };
        },
    },

    location: {
        required: false,
        default: "bottom",
    },

    noIcon: {
        type: Boolean,
        required: false,
        default: false,
    },

    noButton: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const snackbar = ref(false);
const hoverSnackbar = ref(false);
const persistMsg = ref(false);

const progressVal = ref(0);
const progressBarColor = ref("rgba(255,255,255,0.75)");

const animationState = ref("Initial");
const disabled = ref(false);
const duration = ref(5000);

// Keep track of user's mouse hover on snackbar to prevent snackbar from hiding
watch(hoverSnackbar, () => {
    if (persistMsg.value) return;
    if (progressVal.value === 0 && animationState.value === "Finished") {
        forwardAnimate();
    } else {
        reverseAnimate();
    }
});

// Animation transition
const cubicBezierNumber = useTransition(progressVal, {
    disabled,
    duration,
    transition: [0.75, 0, 0.25, 1],
    onStarted() {
        animationState.value = "Playing";
    },
    onFinished() {
        animationState.value = "Finished";
        if (cubicBezierNumber.value === 100 && duration.value !== 0) {
            resetAnimate();
            hideSnackBar();
        }
    },
});

// Main function to display snackbar
const showSnackBar = () => {
    // TODO: get rgbLinearShade(
    //   0.45,
    //   this.$vuetify.theme.currentTheme[this.snackConfig.snackColor]
    // ) Working.
    // progressBarColor.value = prop.snackConfig.snackColor

    // TODO: check why do we need to use nextTick for updated prop
    nextTick(() => {
        persistMsg.value = prop.snackConfig.snackTimeout === -1 ? true : false;
        snackbar.value = true;
        animateProgressBar();
    });
};

// Hide snackbar and reset animation (Can be exported if required)
const hideAndResetSnackBar = () => {
    resetAnimate();
    hideSnackBar();
};

// Animate of progress bar
function animateProgressBar() {
    if (persistMsg.value) return;
    resetAnimate();
    nextTick(() => {
        forwardAnimate();
    });
}

function hideSnackBar() {
    snackbar.value = false;
}

function resetAnimate() {
    progressVal.value = 0;
    // Be aware, this is not the same a duration of 0. Disabled transitions track the source value synchronously.
    // duration.value = 0
    disabled.value = true;
}

// 0% to 100% of progress bar
function forwardAnimate() {
    if (progressVal.value === 0) {
        disabled.value = false;
        duration.value = prop.snackConfig.snackTimeout;
        progressVal.value = 100;
    }
}

// 100% to 0% of progress bar
function reverseAnimate() {
    if (progressVal.value === 100) {
        disabled.value = false;
        duration.value = 250;
        progressVal.value = 0;
    }
}

defineExpose({ showSnackBar });
</script>

<style lang="scss" scoped>
.custom-snackbar::v-deep(.v-snack__action) {
    margin-right: 0px !important;
}

.progressBarTransition {
    transition: cubic-bezier(0.75, 0, 0.25, 1) !important;
}
</style>